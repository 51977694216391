<template>
  <div class="rich_text_editor_wrapper">
    <div class="rich_text_editor">
      <!-- {{ content }} -->
      <Table :content.sync="content_" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'RichTextEditor',
  components: {
    Table: () => import('./Components/Routes/Tables/'),
  },
  props: {
    content: {
      type: String,
    },
  },
  mounted() {},
  computed: {
    content_: {
      // getter
      get: function() {
        return this.content;
      },
      // setter
      set: function(newValue) {
        this.$emit('update:content', newValue);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
//ref: reset css https://stackoverflow.com/a/15903168/3553367
/* .rich_text_editor_wrapper  {
  all: initial;
  * {
    all: unset;
  }
} */
.rich_text_editor  {
}
</style>
